// LaravelService.ts
export class LaravelService {
    constructor() { }
    projectName() {
        var _a;
        const location = window.location.href;
        const sections = location.split('/');
        return (_a = sections.pop()) !== null && _a !== void 0 ? _a : '';
    }
    csrfToken() {
        let token = '';
        let meta = document.querySelector('meta[name="csrf-token"]');
        if (meta && meta.getAttributeNode('content')) {
            token = meta.getAttribute('content') || 'no-csrf-token-provided';
        }
        return token;
    }
}
