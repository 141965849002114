/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
import { createApp } from 'vue';
import App from './App.vue';
import Project from './Project.vue';
import BookletBuilder from "./BookletBuilder.vue";
const app = createApp(App);
const project = createApp(Project);
const booklet = createApp(BookletBuilder);
if (document.getElementById('projekte')) {
    app.mount('#projekte');
}
if (document.getElementById('elements')) {
    project.mount('#elements');
}
if (document.getElementById('booklet')) {
    booklet.mount('#booklet');
}
